import React from 'react';

export const Lobby: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="82"
    height="82"
    viewBox="0 0 82 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M70.8182 20.5556V12.3333C70.8182 5.55 65.7864 0 59.6364 0H22.3636C16.2136 0 11.1818 5.55 11.1818 12.3333V20.5556C5.03182 20.5556 0 26.1056 0 32.8889V53.4444C0 60.2278 5.03182 65.7778 11.1818 65.7778V69.8889C11.1818 72.15 12.8591 74 14.9091 74C16.9591 74 18.6364 72.15 18.6364 69.8889V65.7778H63.3636V69.8889C63.3636 72.15 65.0409 74 67.0909 74C69.1409 74 70.8182 72.15 70.8182 69.8889V65.7778C76.9682 65.7778 82 60.2278 82 53.4444V32.8889C82 26.1056 76.9682 20.5556 70.8182 20.5556ZM18.6364 12.3333C18.6364 10.0722 20.3136 8.22222 22.3636 8.22222H59.6364C61.6864 8.22222 63.3636 10.0722 63.3636 12.3333V23.7622C61.09 26.0233 59.6364 29.2711 59.6364 32.8889V41.1111H22.3636V32.8889C22.3636 29.2711 20.91 26.0233 18.6364 23.7622V12.3333ZM74.5455 53.4444C74.5455 55.7056 72.8682 57.5556 70.8182 57.5556H11.1818C9.13182 57.5556 7.45455 55.7056 7.45455 53.4444V32.8889C7.45455 30.6278 9.13182 28.7778 11.1818 28.7778C13.2318 28.7778 14.9091 30.6278 14.9091 32.8889V49.3333H67.0909V32.8889C67.0909 30.6278 68.7682 28.7778 70.8182 28.7778C72.8682 28.7778 74.5455 30.6278 74.5455 32.8889V53.4444Z"
      fill="currentColor"
    />
  </svg>
);
