import { Alert } from '@/components/basic';
import useTimeZones from '@/hooks/useTimeZones';
import { findTimeZoneFromId, hasTimezoneMismatch } from '@/lib/utils';
import { useAuthenticated } from '@gettactic/hooks';
import Link from 'next/link';

type TimeZoneMismatchProps = {
  officeId: string;
  timezone: string;
  className: string;
  onClose: () => void;
  embedded?: boolean;
};
export default function TimeZoneMismatchAlert({
  officeId,
  timezone,
  className = '',
  onClose,
  embedded = false
}: TimeZoneMismatchProps): JSX.Element {
  const show = hasTimezoneMismatch(timezone);
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const settingsPerms = !!authenticatedUser.permissions.settings;
  const timezones = useTimeZones();
  const selectedTimeZone = findTimeZoneFromId(timezone, timezones) || null;
  const timezoneHumanName = selectedTimeZone
    ? selectedTimeZone.label
    : timezone;

  if (!show) {
    return <></>;
  }

  return (
    <div className={className}>
      <Alert
        variant="tacticAlert"
        canDismiss={false}
        alertTitle={`Times shown are in ${timezoneHumanName}`}
        alertMessage=""
        className="mt-2 px-4 py-2"
      >
        {settingsPerms ? (
          <p className="text-sm">
            To change the office time zone, please{' '}
            <Link href={`/settings/offices/${officeId}`}>
              <a
                onClick={onClose}
                className="cursor-pointer text-primary-darker underline"
                target={embedded ? '_blank' : undefined}
                rel={embedded ? 'noopener noreferrer' : undefined}
              >
                click here
              </a>
            </Link>
          </p>
        ) : null}
      </Alert>
    </div>
  );
}
