import React from 'react';

export const AccessibilityRestroom: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="83"
    height="83"
    viewBox="0 0 73 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.7942 13.0981V25.6679M70.0001 66.5475H61.629L53.6273 45.5815H27.7942V25.6679M27.7942 25.6679H48.2847"
      stroke="#D1D5D9"
      stroke-width="5.8215"
      stroke-linecap="round"
    />
    <ellipse
      cx="28.0125"
      cy="9.65302"
      rx="9.48318"
      ry="9.65302"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.42377 30.4665C8.6427 31.0855 7.90961 31.7627 7.23079 32.4924L7.0073 32.7326C4.63916 35.2782 2.85053 38.3065 1.76443 41.6093L1.70646 41.7855L1.57478 42.186C0.531578 45.3583 0 48.6765 0 52.0159V52.1737C0 55.408 0.532063 58.6202 1.57488 61.6816L1.67786 61.9842C2.65265 64.8479 4.06145 67.5448 5.85501 69.9808C7.64425 72.4112 9.85152 74.5039 12.3737 76.1611L13.1885 76.6966L13.299 76.7691C16.2867 78.7319 19.6062 80.1354 23.0957 80.9111C26.5739 81.6842 30.1781 81.6965 33.6615 80.9471L33.7438 80.9294C37.2925 80.1659 40.669 78.7518 43.7026 76.7585L43.9193 76.6161C47.0838 74.537 49.7497 71.7843 51.7262 68.5547L51.8186 68.4039L54.205 64.505C54.6683 63.7482 54.9134 62.8781 54.9134 61.9908C54.9134 61.1839 54.3882 60.4709 53.6175 60.2317L50.5835 59.2899L49.8852 59.0426C48.2167 58.4518 46.3649 59.1446 45.494 60.6856C45.3441 60.9507 45.1617 61.196 44.9509 61.4157L41.5052 65.0073L40.8905 65.6481C38.8294 67.7965 36.2292 69.3518 33.3615 70.1515L32.5471 70.3787C30.2012 71.0334 27.735 71.1324 25.3441 70.6679C22.9475 70.2015 20.6769 69.2327 18.6819 67.8253L18.4613 67.6696L18.0995 67.4144C16.1895 66.0669 14.5199 64.4076 13.1606 62.5061C11.8163 60.6254 10.9954 58.4217 10.7817 56.1199L10.6138 54.3121L10.4978 53.0597C10.2489 50.3738 10.5424 47.6653 11.3609 45.0951C12.1727 42.5471 13.6693 40.2712 15.6871 38.5162L16.5652 37.7524L17.1182 37.2715C19.1281 35.5233 19.7085 32.6407 18.5317 30.2509L17.0232 27.1874C16.7244 26.5806 16.1068 26.1963 15.4304 26.1963C15.03 26.1963 14.6414 26.3316 14.3276 26.5803L9.42377 30.4665Z"
      fill="currentColor"
    />
  </svg>
);
