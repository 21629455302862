import { Button } from '@/components/basic';
import IconButton from '@/components/basic/IconButton';
import { DivisionOfficeSelector } from '@/components/layouts/DivisionOfficeSelector';
import { OfficeSelector } from '@/components/layouts/OfficeSelector';
import { FeedbackModal } from '@/components/partial/Modals/FeedbackModal';
import { NotificationDrawer } from '@/components/partial/NotificationDrawer';
import { ReserveModal } from '@/components/reserve/Reserve/ReserveModal';
import useCommonContext from '@/hooks/useCommonContext';
import { api } from '@/lib/api/api';
// import { DEFAULT_THEME, RED_BULL_THEME } from "../../../theme";
import { classNames } from '@/lib/classNames';
import { TACTIC_HELP_URL } from '@/lib/constants';
import {
  isFacilityTicketEnabled,
  isHpe,
  isTacticStaff
} from '@/lib/featureFlags';
import {
  isOldDashboardDefault,
  isWorkingLocationsEnabled
} from '@/lib/featureFlags';
import { imageUrl } from '@/lib/utils';
import { ResourceType } from '@gettactic/api';
import {
  openZendeskChat,
  useHideZendeskChat
} from '@gettactic/helpers/src/zendesk';
import { useDivisionsMenuQuery } from '@gettactic/hooks';
import { useAuthenticated } from '@gettactic/hooks';
import { Menu, Transition } from '@headlessui/react';
import {
  BellIcon,
  ChevronDownIcon,
  ExclamationIcon
} from '@heroicons/react/outline';
import { StopIcon } from '@heroicons/react/solid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  Fragment,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import { IoPersonCircleSharp } from 'react-icons/io5';

interface AppTopNavProps {
  meetingsDisabled: boolean;
  hideOfficeMenu: boolean;
  deskBookingDisabled: boolean;
  theme?: string;
  changeTheme?: () => void;
}

interface MenuItemLink {
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  children: React.ReactNode;
}

export const MenuItemLink = forwardRef<HTMLAnchorElement, MenuItemLink>(
  (props, ref) => {
    const { href, children, onClick, ...rest } = props;
    return href ? (
      <Link href={href}>
        <a
          ref={ref}
          {...rest}
          onClick={onClick}
          className="flex w-full justify-between px-4 py-2 text-left leading-5 hover:cursor-pointer hover:bg-gray-200"
        >
          {children}
        </a>
      </Link>
    ) : (
      <a
        className="flex w-full justify-between px-4 py-2 text-left leading-5 hover:cursor-pointer hover:bg-gray-200"
        onClick={onClick}
      >
        {children}
      </a>
    );
  }
);

// TODO: Verify if we can remove the office selection from the new dashboard.
export const AppTopNav = ({
  meetingsDisabled,
  hideOfficeMenu,
  deskBookingDisabled
}: AppTopNavProps) => {
  const {
    userContext: { authenticatedUser, currentOffice },
    setUserContext
  } = useAuthenticated();
  const queryClient = useQueryClient();
  const offices = authenticatedUser.offices;
  const divisions = useDivisionsMenuQuery();
  const hasDivisions = divisions.data?.tree?.children?.length;
  const [showImpersonation, setShowImpersonation] = useState<boolean>(
    !!authenticatedUser?.user?.is_being_impersonated
  );
  const [showImpersonationForm, setShowImpersonationForm] =
    useState<boolean>(false);
  const [impersonationEmail, setImpersonationEmail] = useState<string>('');
  const [openNotificationPanel, setOpenNotificationPanel] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const organization = authenticatedUser?.organization;
  const facilityTicketEnabled = organization
    ? isFacilityTicketEnabled(organization.id)
    : false;

  const router = useRouter();
  const [isReserveModalOpen, setIsReserveModalOpen] = useState(false);
  const [reserveType, setReserveType] = useState<ResourceType>('desk');
  const { start } = useCommonContext();

  const allowOldDashboard = !isHpe(authenticatedUser);

  const { data: newsData } = useQuery(
    ['organizations.news', currentOffice.id],
    async () => {
      const res = await api.client.organizations.news(currentOffice.id);
      return res.result;
    }
  );

  const impersonateMutation = useMutation(
    async ({ email }: { email: string }) => {
      await api.client.users.impersonate({ email });
    },
    {
      onSuccess: () => {
        // Invalidate everything
        queryClient.invalidateQueries();
        window.location.assign('/dashboard');
      }
    }
  );

  const stopMutation = useMutation(
    async () => {
      const result = await fetch(
        `${process.env.NEXT_PUBLIC_TACTIC_API_URL}/auth/impersonate/end`,
        {
          credentials: 'include'
        }
      );
    },
    {
      onSuccess: () => {
        window.location.assign('/dashboard');
      },
      onError: () => {
        window.location.assign('/dashboard');
      }
    }
  );

  useEffect(() => {
    if (showImpersonationForm) {
      document.getElementById('impersonateEmail')?.focus();
    }
  }, [showImpersonationForm]);

  const closeFeedbackModal = useCallback(() => {
    setFeedbackModalOpen(false);
  }, []);

  const openFeedbackModal = useCallback(() => {
    setFeedbackModalOpen(true);
  }, []);

  useHideZendeskChat();

  const showImpersonate = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (!authenticatedUser?.permissions.impersonate) {
      return;
    }

    if (!showImpersonation) {
      setShowImpersonation(true);
    }
    setShowImpersonationForm(true);
  };

  const onImpersonate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!authenticatedUser?.permissions.impersonate) {
      return;
    }

    setShowImpersonation(false);

    const email: string | null = impersonationEmail.trim().toLowerCase() || '';

    if (email !== null && email !== '') {
      impersonateMutation.mutate({ email });
    }
  };

  const onStopImpersonate = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    stopMutation.mutate();
  };

  function getNewUrl(organizationSlug: string) {
    const currentHostName = window.location.hostname;
    const newHostName = currentHostName.replace(
      organization?.slug ?? '',
      organizationSlug
    );
    // this way we keep same protocol, domain, path, params, etc
    const newUrl = window.location.href.replace(currentHostName, newHostName);
    return newUrl;
  }

  const accessToNewDefaultOld = isOldDashboardDefault(authenticatedUser);
  const oldDashboard = router.query.old;
  const newDashboard = router.query.new;

  return (
    <>
      <div className="flex flex-1 justify-between px-4">
        <div className="flex items-center">
          {organization?.logo_url && (
            <div className="flex flex-row gap-2">
              <img
                alt={organization.name}
                src={imageUrl(organization.logo_url, 'logo_max_width_300')}
                className="h-10 w-auto"
                title={organization.name}
              />
              {isTacticStaff(authenticatedUser) &&
              authenticatedUser.organizations?.length > 0 ? (
                <>
                  <span className="border-gray-900 border-r border-solid" />
                  <Menu>
                    <Menu.Button className="mr-4">
                      <ChevronDownIcon className="h-5 w-8 text-gray-900" />
                    </Menu.Button>
                    <Menu.Items className="absolute top-[3.0rem] z-10 grid gap-y-2 divide-y divide-gray-100 rounded-lg border border-gray-200 bg-white p-1 shadow-lg">
                      {authenticatedUser.organizations.map((org) => (
                        <a
                          href={getNewUrl(org.slug)}
                          key={org.id}
                          className=" rounded-lg p-2 hover:bg-gray-100"
                        >
                          <Menu.Item>
                            <div className="flex items-center gap-2">
                              {!!org.logo_url && (
                                <img
                                  alt={org.name}
                                  src={imageUrl(
                                    org.logo_url,
                                    'logo_max_width_300'
                                  )}
                                  className="h-10 w-16 object-contain pr-2"
                                  title={org.name}
                                />
                              )}
                              <p>{org.name}</p>
                            </div>
                          </Menu.Item>
                        </a>
                      ))}
                    </Menu.Items>
                  </Menu>
                </>
              ) : null}
            </div>
          )}
          {organization && !organization.logo_url && (
            <h3 className="mr-4 font-bold text-2xl text-tertiary">
              {organization.name}
            </h3>
          )}
          {/**
           * TODO: Kind of a lot here but essentially we want to show the office selector on the new dashboard
           * but not on the old dashboard & can't pass it via layout props. Essentially we replicate the same
           * logic as used on the dashboard page. This can be removed once we no longer support the old dashboard.
           */}
          <div className="ml-4 flex">
            {(!hideOfficeMenu && offices && !hasDivisions) ||
            oldDashboard ||
            (accessToNewDefaultOld && !newDashboard) ? (
              <OfficeSelector />
            ) : null}
            {!hideOfficeMenu && offices && hasDivisions ? (
              <DivisionOfficeSelector
                selectedOffice={currentOffice}
                onSelectOffice={(office) => {
                  setUserContext({
                    type: 'updateCurrentOffice',
                    payload: office?.id || ''
                  });
                  router.replace('/map');
                }}
              />
            ) : null}
          </div>
        </div>
        <div className="flex items-center gap-2" role="alert">
          {authenticatedUser && showImpersonation && (
            <>
              <div className="mx-auto flex animate-ping-slow items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              {authenticatedUser.user?.is_being_impersonated ? (
                <div className="flex items-center text-tertiary">
                  <p>
                    Impersonating: <b>{authenticatedUser.user.email}</b>
                  </p>
                  <button
                    className="px-4 py-2 text-left leading-5 hover:bg-gray-200"
                    onClick={onStopImpersonate}
                    title="Stop"
                  >
                    <StopIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              ) : null}
              {showImpersonationForm && (
                <form
                  className="ml-3 flex w-full max-w-sm justify-between"
                  onSubmit={onImpersonate}
                >
                  <input
                    className="block w-full rounded-md border-gray-300 shadow-sm focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-primary sm:text-sm"
                    type="text"
                    id="impersonateEmail"
                    placeholder="Enter email address"
                    name="username"
                    inputMode="email"
                    onChange={(e) => {
                      setImpersonationEmail(e.currentTarget.value);
                    }}
                  />
                  <button
                    className="ml-3 inline-flex items-center rounded-md border border-transparent bg-tertiary px-3 py-2 font-bold text-sm text-tertiary-text leading-4 shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
                    type="submit"
                  >
                    Impersonate
                  </button>
                </form>
              )}
            </>
          )}
        </div>
        <div className="ml-4 flex items-center justify-end lg:ml-6">
          <>
            {!meetingsDisabled ? (
              <>
                <a
                  href="#"
                  className={classNames(
                    'mr-2 font-bold',
                    'text-primary hover:text-primary-hover'
                  )}
                  onClick={() => {
                    setReserveType('meeting_room');
                    setIsReserveModalOpen(true);
                  }}
                >
                  Reserve Room
                </a>{' '}
              </>
            ) : null}
            {!deskBookingDisabled ? (
              <>
                {/* biome-ignore lint/complexity/noUselessFragments: <explanation> */}
                <>&nbsp; &nbsp;</>
                <Button
                  variant="primary"
                  type="button"
                  layout="rounded"
                  font="boldPrimary"
                  onClick={() => {
                    setReserveType('desk');
                    setIsReserveModalOpen(true);
                  }}
                >
                  Reserve Desk
                </Button>
              </>
            ) : null}
            <ReserveModal
              reserveModal={isReserveModalOpen}
              resourceType={reserveType}
              embedded={false}
              start={start}
              onClose={() => setIsReserveModalOpen(!isReserveModalOpen)}
            />
          </>
          <IconButton
            onPress={() => setOpenNotificationPanel(true)}
            Icon={BellIcon}
            label="View notifications"
          />
          <NotificationDrawer
            openSidePanel={openNotificationPanel}
            setOpenSidePanel={setOpenNotificationPanel}
            newsData={newsData?.elements ?? []}
          />
          <div className="relative mr-4 ml-1">
            <Menu as="div">
              <Menu.Button className="flex w-full justify-center font-medium text-tertiary leading-5 transition duration-150 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2">
                {authenticatedUser ? (
                  <div className="flex h-10 place-items-center">
                    {authenticatedUser.user?.profile_photo_url ? (
                      <img
                        className="mr-2 w-10 rounded-full object-cover shadow-md"
                        src={imageUrl(
                          authenticatedUser.user.profile_photo_url,
                          '40x40_face'
                        )}
                        alt="profile menu"
                      />
                    ) : (
                      <IoPersonCircleSharp className="w-10" />
                    )}
                    <p>
                      {authenticatedUser?.user
                        ? authenticatedUser.user.name
                        : 'N/A'}
                    </p>
                    <ChevronDownIcon className="h-4 w-4" />
                  </div>
                ) : null}
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-30 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      <MenuItemLink href="/settings/profile">
                        My Profile
                      </MenuItemLink>
                    </Menu.Item>
                    {router.query.new ||
                    (isWorkingLocationsEnabled(authenticatedUser) &&
                      allowOldDashboard &&
                      !router.query.old) ? (
                      <Menu.Item>
                        <MenuItemLink href="/dashboard?old=1">
                          Old Dashboard
                        </MenuItemLink>
                      </Menu.Item>
                    ) : null}
                    {router.query.old ? (
                      <Menu.Item>
                        <MenuItemLink href="/dashboard?new=1">
                          New Dashboard
                        </MenuItemLink>
                      </Menu.Item>
                    ) : null}
                    <Menu.Item>
                      <MenuItemLink onClick={openZendeskChat}>
                        Chat Support
                      </MenuItemLink>
                    </Menu.Item>
                    <a
                      rel="noreferrer"
                      href={TACTIC_HELP_URL}
                      target="_blank"
                      className="flex w-full justify-between px-4 py-2 text-left leading-5 hover:cursor-pointer hover:bg-gray-200"
                    >
                      Help Center
                    </a>
                    <Menu.Item>
                      <MenuItemLink onClick={openFeedbackModal}>
                        {facilityTicketEnabled
                          ? 'Facility Ticket'
                          : 'Share Feedback'}
                      </MenuItemLink>
                    </Menu.Item>
                  </div>

                  {authenticatedUser?.permissions.impersonate && (
                    <div className="py-1">
                      <Menu.Item>
                        <MenuItemLink href="#" onClick={showImpersonate}>
                          Impersonate
                        </MenuItemLink>
                      </Menu.Item>
                    </div>
                  )}

                  <div className="py-1">
                    <Menu.Item>
                      <MenuItemLink
                        onClick={(ev) => {
                          ev.preventDefault();
                          location.href = `/auth/logout?returnUrl=${encodeURIComponent(
                            `${window.location.origin}?logout=true`
                          )}`;
                        }}
                        href={'/auth/logout'}
                      >
                        Log out
                      </MenuItemLink>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            {authenticatedUser?.user?.email &&
            feedbackModalOpen &&
            currentOffice &&
            organization ? (
              <FeedbackModal
                open={feedbackModalOpen}
                close={closeFeedbackModal}
                email={authenticatedUser.user.email}
                organizationSlug={organization.slug}
                officeId={currentOffice.id}
                officeName={currentOffice.name}
                userName={authenticatedUser.user.name}
                facilityTicketsEnabled={facilityTicketEnabled}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(AppTopNav);
