import { DatePicker, Input, Label } from '@/components/basic';
import { classNames } from '@/lib/classNames';
import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Frequency, RRule, Weekday } from 'rrule';

const validPeriodicityOptions: {
  label: string;
  value: AdvancedFormState['freq'];
}[] = [
  { label: 'Day', value: RRule.DAILY },
  { label: 'Week', value: RRule.WEEKLY },
  { label: 'Month', value: RRule.MONTHLY },
  { label: 'Year', value: RRule.YEARLY }
];
//

const repeatOnDays: { label: string; value: Weekday }[] = [
  { label: 'Sunday', value: RRule.SU },
  { label: 'Monday', value: RRule.MO },
  { label: 'Tuesday', value: RRule.TU },
  { label: 'Wednesday', value: RRule.WE },
  { label: 'Thursday', value: RRule.TH },
  { label: 'Friday', value: RRule.FR },
  { label: 'Saturday', value: RRule.SA }
];

type Props = {
  setAdvanced: React.Dispatch<React.SetStateAction<boolean>>;
  state: AdvancedFormState;
  setState: (x: AdvancedFormState) => void;
  hideWeekends?: boolean;
  minUntilDate: Date;
};

export type AdvancedFormState = {
  interval: number;
  freq: Frequency;
  byweekday: Weekday[];
  until: Date | null;
};

export default function ReserveFieldRecurringAdvancedForm({
  setAdvanced,
  state,
  setState,
  hideWeekends,
  minUntilDate
}: Props) {
  const [untilDate, setUntilDate] = useState(
    state.until ?? addDays(minUntilDate, 30)
  );
  useEffect(() => {
    if (state.until) {
      setUntilDate(state.until);
    }
  }, [state.until]);

  return (
    <div className="rounded-lg bg-gray-50 p-2">
      <div className="flex items-center">
        <h4 className="font-bold text-secondary-bg">Options</h4>
        <button
          className="grow text-right  text-sm"
          onClick={(ev) => {
            ev.preventDefault();
            setAdvanced(false);
          }}
        >
          Close
        </button>
      </div>

      <div className="flex items-center">
        <div className="basis-1/4 text-sm">Repeat every</div>
        <div className="flex basis-3/4 items-center">
          <div className="basis-1/4">
            <Input
              onChange={(ev) => {
                if (ev.currentTarget.valueAsNumber < 0) {
                  return;
                }
                setState({
                  ...state,
                  interval: ev.currentTarget.valueAsNumber
                });
              }}
              value={state.interval.toString()}
              type="number"
              className="resource-select"
            />
          </div>

          <Select
            onChange={(val) => {
              if (!val) {
                return;
              }
              setState({ ...state, freq: val.value });
            }}
            value={validPeriodicityOptions.find((x) => x.value === state.freq)}
            className="resource-select ml-2 flex-grow"
            classNamePrefix="react-select"
            options={validPeriodicityOptions}
          />
        </div>
      </div>
      <div className="mt-2 flex items-center">
        <div className="basis-1/4  text-sm">Repeat on</div>
        <div className="flex basis-3/4 items-center gap-3 space-x-1">
          {repeatOnDays
            .filter((day) =>
              hideWeekends
                ? !(day.value === RRule.SU || day.value === RRule.SA)
                : true
            )
            .map((day) => {
              const idx = state.byweekday.findIndex(
                (x) => x.weekday === day.value.weekday
              );
              return (
                <div
                  key={day.value.toString()}
                  onClick={((idx, value) => () => {
                    const byweekday: Weekday[] = [...state.byweekday];
                    if (idx > -1) {
                      byweekday.splice(idx, 1);
                    } else {
                      byweekday.push(value);
                    }
                    setState({ ...state, byweekday: byweekday });
                  })(idx, day.value)}
                  className={classNames(
                    'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border  text-center font-bold capitalize ',
                    idx > -1
                      ? 'bg-primary text-white '
                      : 'border-gray-300 text-secondary-bg'
                  )}
                  title={day.label}
                >
                  {day.label[0]}
                </div>
              );
            })}
        </div>
      </div>
      <div className="mt-2">
        <div className="basis-1/4  text-sm">Ends</div>
        <div className="flex flex-col">
          <Label radio className="my-1 block">
            <Input
              type="radio"
              onChange={() => {
                setState({ ...state, until: null });
              }}
              checked={state.until === null}
            />
            <div className="ml-2 text-base">Never</div>
          </Label>
          <div className="flex items-center justify-between">
            <Label radio className="my-1 block ">
              <Input
                type="radio"
                onChange={() => {
                  setState({ ...state, until: untilDate });
                }}
                checked={state.until !== null}
              />
              <div className="ml-2 text-base">On</div>
            </Label>
            <div className="ml-4 flex grow basis-3/4">
              <DatePicker
                className={classNames(
                  'input w-full rounded-md text-left',
                  !state.until && 'bg-gray-100 text-gray-400'
                )}
                calendarClassName="tailwind-datepicker"
                dateFormat={'eeee, LLLL d, yyyy'}
                selected={untilDate}
                placeholderText={!state.until ? 'Never' : ''}
                onChange={(until) => {
                  setState({ ...state, until });
                }}
                minDate={minUntilDate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
