import React from 'react';

export const Data: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M62.2222 0H7.77778C3.5 0 0 3.5 0 7.77778V62.2222C0 66.5 3.5 70 7.77778 70H62.2222C66.5 70 70 66.5 70 62.2222V7.77778C70 3.5 66.5 0 62.2222 0ZM62.2222 62.2222H7.77778V7.77778H62.2222V62.2222ZM15.5556 27.2222H23.3333V54.4444H15.5556V27.2222ZM31.1111 15.5556H38.8889V54.4444H31.1111V15.5556ZM46.6667 38.8889H54.4444V54.4444H46.6667V38.8889Z"
      fill="currentColor"
    />
  </svg>
);
