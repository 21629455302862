import Logo from '@/components/basic/icons/logo';
import {
  AppMobileNav,
  AppSideNav,
  AppTopNav
} from '@/components/layouts/AppLayout';
import useInstallTelemetry from '@/hooks/useInstallTelemetry';
import { useToggle } from '@/hooks/useToggle';
import { isTacticStaff } from '@/lib/featureFlags';
import { getNewUrl, imageUrl } from '@/lib/utils';
import { useAuthenticated } from '@gettactic/hooks';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { DEFAULT_THEME } from '../../../theme';
import { applyTheme } from '../../../theme/utils';

type AppLayoutBodyProps = {
  children: React.ReactNode;
  hideOfficeMenu?: boolean;
  changeTheme?: () => void;
  theme?: string;
};

const themeTagMapper = (theme: string): string => {
  return theme === 'base' ? '' : `${theme}-theme-tag`;
};

export const AppLayoutBody = ({
  children,
  hideOfficeMenu = false
}: AppLayoutBodyProps) => {
  const [isMobileMenuOpen, toggleMobileMenu] = useToggle();
  const [theme, setTheme] = useState(DEFAULT_THEME);
  const {
    userContext: { authenticatedUser },
    userContext
  } = useAuthenticated();
  useInstallTelemetry();

  // Check if organization has meeting rooms disabled
  const meetingsDisabled =
    !authenticatedUser?.organization?.meeting_room_bookings_enabled;
  const deskBookingDisabled =
    !authenticatedUser?.organization?.desk_bookings_enabled;

  useEffect(() => {
    if (!authenticatedUser.organization && authenticatedUser.redirect.url) {
      location.href = authenticatedUser.redirect.url;
    } else if (
      !authenticatedUser.offices?.offices ||
      !authenticatedUser.offices?.offices.length
    ) {
      // Push user back to office setup if no offices exist
      location.href = '/setup/offices';
    }
  }, [authenticatedUser]);

  const organization = authenticatedUser.organization;

  const hostname = location?.hostname;

  useEffect(() => {
    if (hostname) {
      const organizationSlug = hostname.split('.')[0] ?? DEFAULT_THEME;
      applyTheme(organizationSlug);
      setTheme(organizationSlug);
    }
  }, [hostname]);

  return (
    <>
      <header className="relative flex h-16 shrink-0 items-center">
        <div className="absolute inset-y-0 left-0 md:static md:shrink-0 shadow-2xl z-20">
          <Link href="/dashboard">
            <a
              className={
                'flex h-16 w-16 items-center justify-center bg-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary md:w-28'
              }
            >
              {/*<Image*/}
              {/*  src="https://cdn.gettactic.com/tactic_icon_white.svg"*/}
              {/*  alt="Tactic icon - Home"*/}
              {/*  width={45}*/}
              {/*  height={45}*/}
              {/*/>*/}
              <Logo size={45} className="text-secondary-text" />
            </a>
          </Link>
        </div>
        <div className="relative flex flex-1 h-16 shrink-0 items-center bg-tertiary-text">
          <div className="flex w-full ml-20 md:hidden">
            {/* {organization?.logo_url && (
              <img
                alt={organization.name}
                src={imageUrl(organization.logo_url, 'logo_max_width_300')}
                className="mx-auto h-10 w-auto px-2"
                title={organization.name}
              />
            )} */}
            {organization?.logo_url && (
              <div className="flex flex-row gap-2">
                <img
                  alt={organization.name}
                  src={imageUrl(organization.logo_url, 'logo_max_width_300')}
                  className="h-10 w-auto"
                  title={organization.name}
                />
                {isTacticStaff(authenticatedUser) &&
                authenticatedUser.organizations?.length > 0 ? (
                  <>
                    <span className="border-r border-gray-900 border-solid" />
                    <Menu>
                      <Menu.Button className="mr-4">
                        <ChevronDownIcon className="h-5 w-8 text-gray-900" />
                      </Menu.Button>
                      <Menu.Items
                        className="absolute top-[3.0rem] z-10 grid \
                            gap-y-2 rounded-lg bg-white border border-gray-200 \
                            divide-y divide-gray-100 shadow-lg p-1"
                      >
                        {authenticatedUser.organizations.map((org) => (
                          <a
                            href={getNewUrl(org.slug, organization?.slug)}
                            key={org.id}
                            className=" rounded-lg p-2 hover:bg-gray-100"
                          >
                            <Menu.Item>
                              <div className="flex gap-2 items-center">
                                {!!org.logo_url && (
                                  <img
                                    alt={org.name}
                                    src={imageUrl(
                                      org.logo_url,
                                      'logo_max_width_300'
                                    )}
                                    className="h-10 w-16 object-contain pr-2"
                                    title={org.name}
                                  />
                                )}
                                <p>{org.name}</p>
                              </div>
                            </Menu.Item>
                          </a>
                        ))}
                      </Menu.Items>
                    </Menu>
                  </>
                ) : null}
              </div>
            )}
            {organization && !organization.logo_url && (
              <h3 className="ml-20 mr-4 text-2xl font-bold text-tertiary">
                {organization.name}
              </h3>
            )}
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-4 sm:pr-6 md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md p-2 text-tertiary transition duration-150 ease-in-out hover:text-tertiary-hover focus:text-tertiary-focus focus:outline-none"
              aria-expanded="false"
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Toggle menu</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div className="hidden w-full md:flex md:min-w-0 md:flex-1 md:items-center md:justify-between">
            <AppTopNav
              meetingsDisabled={meetingsDisabled}
              hideOfficeMenu={hideOfficeMenu}
              deskBookingDisabled={deskBookingDisabled}
            />
          </div>
          <AppMobileNav
            isMobileMenuOpen={isMobileMenuOpen}
            closeMobileMenu={toggleMobileMenu}
            meetingsDisabled={meetingsDisabled}
            deskBookingDisabled={deskBookingDisabled}
          />
        </div>
      </header>
      <div className="flex min-h-0 flex-1 md:overflow-hidden">
        <AppSideNav permissions={authenticatedUser.permissions} theme={theme} />
        {userContext?.currentOffice?.id ? (
          <div
            className={clsx(
              'w-full min-w-0 border-t border-gray-200',
              themeTagMapper(theme)
            )}
          >
            {/* {isTacticStaff(authenticatedUser) ? <DesignSwitcher /> : null} */}
            {children}
          </div>
        ) : (
          <h3>Loading office...</h3>
        )}
      </div>
    </>
  );
};

export default AppLayoutBody;
