import React from 'react';

export const Coffee: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M57.3333 27V55C57.3333 58.85 54.2333 62 50.4444 62H29.7778C25.9889 62 22.8889 58.85 22.8889 55V27H57.3333ZM71.1111 20H16V55C16 62.735 22.1656 69 29.7778 69H50.4444C58.0567 69 64.2222 62.735 64.2222 55V44.5H71.1111C74.9344 44.5 78 41.385 78 37.5V27C78 23.115 74.9344 20 71.1111 20ZM64.2222 37.5V27H71.1111V37.5H64.2222Z"
      fill="currentColor"
    />
    <path
      d="M33.1493 0.358757L34.5409 1.39576C35.6055 2.18907 35.6117 3.41644 34.5525 3.66618V3.66618C33.1819 3.98933 33.6904 5.79402 35.3 6.31878L35.8279 6.49091L36.0045 6.54849C38.2406 7.27747 39.3128 9.65976 37.8389 10.6208V10.6208C37.7348 10.6887 37.6203 10.7466 37.4969 10.7936V10.7936C35.7361 11.4645 36.3895 13.8763 38.5951 14.847L39.0926 15.0659C40.4372 15.6578 41.3911 16.8541 41.3911 17.9488L41.3911 18.6686"
      stroke="currentColor"
    />
    <path
      d="M21.5473 0.358757L22.9389 1.39576C24.0034 2.18907 24.0097 3.41644 22.9504 3.66618V3.66618C21.5798 3.98933 22.0884 5.79402 23.6979 6.31878L24.2259 6.49091L24.4025 6.54849C26.6386 7.27747 27.7107 9.65976 26.2368 10.6208V10.6208C26.1327 10.6887 26.0182 10.7466 25.8949 10.7936V10.7936C24.134 11.4645 24.7875 13.8763 26.9931 14.847L27.4905 15.0659C28.8352 15.6578 29.7891 16.8541 29.7891 17.9488L29.7891 18.6686"
      stroke="currentColor"
    />
    <path
      d="M45.5119 0.358757L46.9035 1.39576C47.968 2.18907 47.9743 3.41644 46.915 3.66618V3.66618C45.5444 3.98933 46.053 5.79402 47.6625 6.31878L48.1905 6.49091L48.3671 6.54849C50.6032 7.27747 51.6753 9.65976 50.2014 10.6208V10.6208C50.0973 10.6887 49.9828 10.7466 49.8595 10.7936V10.7936C48.0986 11.4645 48.7521 13.8763 50.9577 14.847L51.4551 15.0659C52.7998 15.6578 53.7537 16.8541 53.7537 17.9488L53.7537 18.6686"
      stroke="currentColor"
    />
    <rect y="68.7253" width="80" height="5.27473" fill="currentColor" />
  </svg>
);
