import React from 'react';

export const ServerRoom: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M71.1111 49.3333V67.1111H8.88889V49.3333H71.1111ZM75.5555 40.4444H4.44444C2 40.4444 0 42.4444 0 44.8889V71.5555C0 74 2 76 4.44444 76H75.5555C78 76 80 74 80 71.5555V44.8889C80 42.4444 78 40.4444 75.5555 40.4444ZM17.7778 64.8889C14.1333 64.8889 11.1111 61.9111 11.1111 58.2222C11.1111 54.5333 14.1333 51.5555 17.7778 51.5555C21.4222 51.5555 24.4444 54.5333 24.4444 58.2222C24.4444 61.9111 21.4667 64.8889 17.7778 64.8889ZM71.1111 8.88889V26.6667H8.88889V8.88889H71.1111ZM75.5555 0H4.44444C2 0 0 2 0 4.44444V31.1111C0 33.5556 2 35.5556 4.44444 35.5556H75.5555C78 35.5556 80 33.5556 80 31.1111V4.44444C80 2 78 0 75.5555 0ZM17.7778 24.4444C14.1333 24.4444 11.1111 21.4667 11.1111 17.7778C11.1111 14.0889 14.1333 11.1111 17.7778 11.1111C21.4222 11.1111 24.4444 14.1333 24.4444 17.7778C24.4444 21.4222 21.4667 24.4444 17.7778 24.4444Z"
      fill="currentColor"
    />
  </svg>
);
