import { useEffect } from 'react';

export function getZendeskKey() {
  return process.env.NEXT_PUBLIC_ZENDESK_KEY;
}

export function isZendeskEnabled() {
  return !!getZendeskKey();
}

export function openZendeskChat() {
  if (!window.zE) {
    return;
  }
  window.zE('messenger', 'show');
  window.zE('messenger', 'open');
}

export function useHideZendeskChat() {
  useEffect(() => {
    if (!isZendeskEnabled()) return;
    if (window.zE) window.zE('messenger', 'hide');
  }, []);
}
