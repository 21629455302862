import { analytics } from '@/hooks/useAnalytics';
import { isSessionReplayForcedForUser } from '@/lib/featureFlags';
import { useAuthenticated } from '@gettactic/hooks';
import { useEffect, useState } from 'react';

export const getFromStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem(key);
  } else {
    return null;
  }
};

export const setToStorage = ({
  key,
  value
}: { key: string; value: string }) => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.setItem(key, value);
  } else {
    return null;
  }
};

/**
 * Helper hook to add telemetry data to
 * the app.
 */
export default function useInstallTelemetry(): void {
  const [installed, setInstalled] = useState(false);
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  useEffect(() => {
    if (installed) {
      return;
    }
    if (
      authenticatedUser &&
      authenticatedUser.user &&
      authenticatedUser.organization
    ) {
      setInstalled(true);
      if (!process.env.NEXT_PUBLIC_SENTRY_DISABLE) {
        import('@sentry/nextjs').then((Sentry) => {
          // // Identify the user for error reporting
          Sentry.setUser({
            id: authenticatedUser.user?.id,
            // This will change within Sentry if the user belongs to more than one org
            // it's more of a pointer to see if there are aggregate events occurring
            // within a single organization
            org: authenticatedUser.slug
          });
          const replay = Sentry.getClient()?.getIntegrationByName?.('Replay');
          if (!!replay && isSessionReplayForcedForUser(authenticatedUser)) {
            //@ts-expect-error sentry no typed
            replay.start();
            //@ts-expect-error sentry no typed
            replay.startBuffering();
            //@ts-expect-error sentry no typed
            console.log('Replay started with id', replay.getReplayId());
          }
        });
      }

      // Identify Call
      analytics.identify(authenticatedUser.user.id, {
        name: authenticatedUser.user.name,
        email: authenticatedUser.user.email,
        phone: authenticatedUser.user.phone,
        timezone: authenticatedUser.user.time_zone,
        company: authenticatedUser.organization?.name,
        job: authenticatedUser.user.organization_user?.title,
        city: authenticatedUser.user.location,
        tactic_user_id: authenticatedUser.user.id,
        //@todo Do we want to send all the roles or pick the first?
        tactic_user_role:
          authenticatedUser.user.organization_user?.roles.join(',')
      });
    }
  }, [installed, authenticatedUser]);
}
