import React from 'react';

export const FemaleRestroom: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 38 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.3333 80V56H38L27.2756 25.48C26.0933 22.2 22.8844 20 19.2533 20H18.7467C15.1156 20 11.8644 22.2 10.7244 25.48L0 56H12.6667V80H25.3333ZM19 16C23.6867 16 27.4444 12.44 27.4444 8C27.4444 3.56 23.6867 0 19 0C14.3133 0 10.5556 3.56 10.5556 8C10.5556 12.44 14.3133 16 19 16Z"
      fill="currentColor"
    />
  </svg>
);
