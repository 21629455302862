import { AppLayoutBody } from '@/components/layouts/AppLayout/AppLayoutBody';
import { AppLayoutSkeleton } from '@/components/layouts/AppLayout/AppLayoutSkeleton';
import { CommonProvider } from '@/contexts/CommonContext';
import { api } from '@/lib/api/api';
import { useGetAuthenticatedUser } from '@gettactic/helpers/src/hooks/useGetAuthenticatedUser';
import { AppAuthenticatedProvider } from '@gettactic/hooks';
import { MantineProvider, useMantineTheme } from '@mantine/core';
import Head from 'next/head';
import React, { ReactNode } from 'react';
import AppLayoutBodyV2 from './AppLayoutBodyV2';

type AppLayoutProps = {
  children: React.ReactNode;
  hideOfficeMenu?: boolean;
  theme?: string;
  changeTheme?: () => void;
};

const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  hideOfficeMenu = false,
  changeTheme,
  theme
}) => {
  const authenticatedUser = useGetAuthenticatedUser(api);
  const defaultTheme = useMantineTheme();

  if (!authenticatedUser) {
    return <AppLayoutSkeleton />;
  }

  return (
    <>
      <Head>
        <meta name="googlebot" content="noindex, nofollow, noarchive" />
      </Head>
      {authenticatedUser ? (
        <AppAuthenticatedProvider
          api={api}
          authenticatedUser={authenticatedUser}
        >
          <MantineProvider inherit theme={defaultTheme}>
            <div className="isolate flex h-screen flex-col bg-gray-100">
              <CommonProvider>
                {authenticatedUser.designSystemVersion === 'v1' ? (
                  <AppLayoutBody
                    hideOfficeMenu={hideOfficeMenu}
                    theme={theme}
                    changeTheme={changeTheme}
                  >
                    {children}
                  </AppLayoutBody>
                ) : (
                  <AppLayoutBodyV2
                    hideOfficeMenu={hideOfficeMenu}
                    theme={theme}
                    changeTheme={changeTheme}
                  >
                    {children}
                  </AppLayoutBodyV2>
                )}
              </CommonProvider>
            </div>
          </MantineProvider>
        </AppAuthenticatedProvider>
      ) : null}
    </>
  );
};

export const getLayout = (page: ReactNode, pageProps: any) => (
  <AppLayout {...pageProps}>{page}</AppLayout>
);

export default AppLayout;
