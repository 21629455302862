import React from 'react';

export const Storage: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 80H80V60H0V80ZM8 65H16V75H8V65ZM0 0V20H80V0H0ZM16 15H8V5H16V15ZM0 50H80V30H0V50ZM8 35H16V45H8V35Z"
      fill="currentColor"
    />
  </svg>
);
