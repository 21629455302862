import { DivisionOfficeRender } from '@/components/layouts/DivisionOfficeSelector/DivisionOfficeRender';
import {
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger
} from '@/components/ui/dropdown-menu';
import { DivisionsTree } from '@gettactic/api';
import { DivisionLabel } from '@gettactic/components/src/data-display/labels/DivisionLabel';
import { Flex } from '@gettactic/components/src/primitives/Flex';
import { useAuthenticated } from '@gettactic/hooks';
import { CheckIcon } from '@heroicons/react/outline';
import { useDivisionOfficeSelector } from './helpers';

export function DivisionTree({
  tree,
  level = 0
}: { tree: DivisionsTree; level: number }) {
  useAuthenticated();
  const { selectedDivision, onSelectDivision } = useDivisionOfficeSelector();
  return (
    <DropdownMenuSub key={tree.id}>
      <DropdownMenuSubTrigger
        onClick={() => {
          onSelectDivision?.(tree);
        }}
      >
        <Flex className={'w-full justify-between'}>
          <DivisionLabel division={tree} showType={false} />
          {selectedDivision?.id === tree.id ? (
            <CheckIcon className="ml-auto h-5 w-5 text-primary" />
          ) : null}
        </Flex>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          <DivisionOfficeRender
            tree={tree}
            allowNoOptions={!tree.children.length}
          />
          {tree.children.map((item) => (
            <DivisionTree tree={item} key={item.id} level={level + 1} />
          ))}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
}
