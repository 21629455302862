import React from 'react';

export const Lockers: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 -3.49691e-06L-3.49691e-06 80L20 80L20 -2.62268e-06L0 -3.49691e-06ZM15 8L15 16L5 16L5 8L15 8ZM80 0L60 -8.74228e-07L60 80L80 80L80 0ZM65 16L65 8L75 8L75 16L65 16ZM30 -2.18557e-06L30 80L50 80L50 -1.31134e-06L30 -2.18557e-06ZM45 8L45 16L35 16L35 8L45 8Z"
      fill="currentColor"
    />
  </svg>
);
