import LoadingSpinner from '@/components/util/LoadingSpinner';
import { api } from '@/lib/api/api';
import { openZendeskChat } from '@/lib/helpers/zendesk';
import { ConfirmModal } from '@gettactic/components/src/overlay/modals/ConfirmModal';
import { useAuthenticated } from '@gettactic/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

export default function DesignSwitcher() {
  const [backToClassicWarning, setBackToClassicWarning] = React.useState(false);

  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const queryClient = useQueryClient();

  const value = authenticatedUser.designSystemVersion;

  const toggleDesignSystem = useMutation(async () => {
    await api.client.userSettings.updateDesignSystem(
      value === 'v1' ? 'v2' : 'v1'
    );
    await queryClient.invalidateQueries([
      'user.properties.design_system_version'
    ]);
  });

  const loading = toggleDesignSystem.isLoading;

  return (
    <div className="flex items-center justify-center bg-white px-0.5 py-2">
      {loading ? (
        <div className="flex items-center space-x-1">
          <LoadingSpinner /> <span>Applying design changes</span>
        </div>
      ) : (
        <button
          disabled={loading}
          onClick={(ev) => {
            if (value === 'v2') {
              setBackToClassicWarning(true);
              return;
            }
            toggleDesignSystem.mutate();
          }}
          className="text-secondary"
        >
          {value === 'v2' ? (
            <>Change to our classic design</>
          ) : (
            <>Try out our new design system</>
          )}
        </button>
      )}

      <ConfirmModal
        isOpen={backToClassicWarning}
        onOpenChange={(isOpen) => {
          setBackToClassicWarning(isOpen);
        }}
        onConfirm={() => {
          setBackToClassicWarning(false);
          toggleDesignSystem.mutate();
        }}
        onCancel={() => setBackToClassicWarning(false)}
        message={
          <div>
            <p>
              The classic design is expected to be sunset on{' '}
              <strong className="text-bold">March 1</strong>. At that time, all
              users will be automatically migrated to our new design. You can
              continue using the classic design until then.
            </p>
            <p>
              We value your feedback! If there’s anything you miss or want to
              share about your experience, please use the{' '}
              <button className="text-primary" onClick={openZendeskChat}>
                support chat
              </button>
              .
            </p>
          </div>
        }
        title={'Switch back to Classic Design'}
      />
    </div>
  );
}
