import { classNames } from '@/lib/classNames';
import { useAuthenticated } from '@gettactic/hooks';
import { CheckIcon, OfficeBuildingIcon } from '@heroicons/react/outline';
import { Menu } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

export function OfficeSelector() {
  const {
    userContext: { authenticatedUser, currentOffice },
    setUserContext
  } = useAuthenticated();

  const offices = authenticatedUser.offices?.offices ?? [];
  const officeLocationOptions = offices.map((x) => ({
    label: x.name,
    value: x.id,
    title: x.name
  }));
  const singleOffice = offices.length === 1;

  return (
    <div className="relative w-full max-w-[275px]">
      <Menu shadow={'md'} width="target">
        <Menu.Target>
          <button
            className={classNames(
              'cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2',
              'inline-flex items-center justify-between px-5 py-2 ',
              'w-full min-w-[200px] rounded-md border bg-white font-medium text-base text-secondary'
            )}
          >
            <span className="flex flex-nowrap items-center space-x-3 overflow-hidden whitespace-nowrap">
              <span>
                <OfficeBuildingIcon className="h-5 w-5" />
              </span>
              <span className="overflow-hidden text-ellipsis whitespace-nowrap pr-2">
                {currentOffice.name}
              </span>
            </span>
            <span className="border-gray-500 border-r-1">
              <IconChevronDown size={16} />
            </span>
          </button>
        </Menu.Target>
        {singleOffice ? null : (
          <Menu.Dropdown className="-mt-2 max-h-[400px] overflow-y-auto">
            {officeLocationOptions.map((office) => (
              <Menu.Item
                key={office.value}
                className="rounded-md border"
                icon={<OfficeBuildingIcon className="h-5 w-5 text-secondary" />}
                onMouseDown={() => {
                  setUserContext({
                    type: 'updateCurrentOffice',
                    payload: office.value
                  });
                }}
                rightSection={
                  office.value === currentOffice.id ? (
                    <CheckIcon className="ml-1 h-5 w-5 text-primary" />
                  ) : null
                }
              >
                {office.label}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        )}
      </Menu>
    </div>
  );
}
