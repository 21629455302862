import { ApiClientError, TacticApiClient } from '@gettactic/api';
import { useQuery } from '@tanstack/react-query';
import { getBaseUrl } from '../strings';
import { webviewLog } from '../webview/webviewLog';

export interface UseGetAuthenticatedUserOptions {
  allowRedirect?: boolean;
}

export function useGetAuthenticatedUser(
  api: TacticApiClient,
  embedded = false,
  options?: UseGetAuthenticatedUserOptions
) {
  const basicUser = useQuery(
    ['users.me'],
    async () => {
      try {
        const res = await api.client.users.me();
        webviewLog(JSON.stringify(res));
        return res.result;
      } catch (e) {
        if (
          options?.allowRedirect !== false &&
          e instanceof ApiClientError &&
          e.response.status === 401
        ) {
          window.location.href = `${process.env.NEXT_PUBLIC_TACTIC_API_URL}/auth/login?returnUrl=${encodeURIComponent(
            `${process.env.NEXT_PUBLIC_TACTIC_DOMAIN_AND_PORT}/dashboard`
          )}`;
          return null;
        }
        throw e;
      }
    },
    {
      staleTime: 5000 * 60 // 5 minutes
    }
  );

  const userReady = !!basicUser.data;

  const organizations = useQuery(
    ['users.organizations'],
    async () => {
      const res = await api.client.users.organizations();
      return res.result;
    },
    {
      staleTime: 5000 * 60, // 5 minutes
      enabled: userReady
    }
  );

  let slug = '';
  if (
    !organizations.isLoading &&
    organizations.data?.elements &&
    organizations.data?.elements.length > 0
  ) {
    let browserSubdomain = '';
    const defaultOrg = organizations.data.elements.filter(
      (organization) =>
        organization.id === basicUser.data?.default_organization_id
    )[0];
    // Set the slug to the user's default organization, otherwise the slug of the first organization they're a part of
    slug = defaultOrg
      ? defaultOrg.slug
      : (organizations.data.elements[0].slug ?? '');

    // If we're in the browser context & there is an intended slug, it has priority
    if (options?.allowRedirect !== false && typeof window !== 'undefined') {
      browserSubdomain = window.location.host.split('.')[0];
      const matchingOrg = organizations.data.elements.find(
        (org) => org.slug === browserSubdomain
      );
      if (matchingOrg) {
        slug = matchingOrg.slug;
      }
    }
  }

  // If we have our user, but no slug exists then we need to create an organization
  if (
    options?.allowRedirect !== false &&
    !basicUser.isLoading &&
    !organizations.isLoading &&
    (!organizations.data ||
      !organizations.data?.elements ||
      organizations.data?.elements.length <= 0) &&
    basicUser &&
    basicUser.data &&
    !slug &&
    !embedded
  ) {
    window.location.href = `${process.env.NEXT_PUBLIC_TACTIC_DOMAIN_AND_PORT}/setup`;
  }

  // Slug is a required on the api-client for the remaining queries
  if (slug) {
    api.updateSlug(slug);
  }

  // If the slug doesn't match, redirect to the proper subdomain
  if (
    options?.allowRedirect !== false &&
    slug &&
    window.location.host.split('.')[0] !== slug &&
    !embedded
  ) {
    window.location.href = `${getBaseUrl(slug)}/dashboard`;
  }

  const user = useQuery(
    ['organizations.getOrgMe'],
    async () => {
      const res = await api.client.organizations.getOrgMe();
      return res.result;
    },
    {
      enabled: !!slug,
      staleTime: 5000 * 60 // 5 minutes
    }
  );

  const organization = useQuery(
    ['organizations.get'],
    async () => {
      const res = await api.client.organizations.get(slug);
      return res.result;
    },
    {
      // Don't run until the slug exists
      enabled: !!slug,
      staleTime: 5000 * 60 // 5 minutes
    }
  );

  const offices = useQuery(
    ['organizations.offices'],
    async () => {
      const res = await api.client.organizations.offices();
      return res.result;
    },
    {
      enabled: !!slug,
      staleTime: 5000 * 60 // 5 minutes
    }
  );

  const designSystem = useQuery(
    ['user.properties.design_system_version'],
    async () => await api.client.userSettings.getDesignSystem(),
    {
      enabled: !!slug,
      staleTime: 5000 * 60 // 5 minutes
    }
  );

  return !!user.data &&
    !!organization.data &&
    !!organizations.data &&
    !!offices.data &&
    !!designSystem.data
    ? api.client.organizations.me(
        user.data,
        organization.data,
        organizations.data,
        offices.data
          ? offices.data
          : { byId: {}, office: null, officeId: null, offices: [] },
        designSystem.data
      )
    : null;
}
