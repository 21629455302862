import React from 'react';

export const Library: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="82"
    height="82"
    viewBox="0 0 82 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M74.4201 2.25225C70.2898 0.675676 65.7502 0 61.3966 0C54.1406 0 46.3265 1.8018 40.9311 6.75676C35.5356 1.8018 27.7215 0 20.4655 0C13.2096 0 5.39546 1.8018 0 6.75676L0 75C0 75 15.0701 69.8198 20.4655 69.8198C27.7215 69.8198 35.5356 70.045 40.9311 75C45.9544 71.1712 55.0709 69.8198 61.3966 69.8198C67.5362 69.8198 81.8621 75 81.8621 75V6.75676C79.6295 4.72973 77.2109 3.37838 74.4201 2.25225ZM74.4201 63.0631C70.327 61.4865 65.8618 60.8108 61.3966 60.8108C55.0709 60.8108 45.9544 63.7387 40.9311 67.5676V15.7658C45.9544 11.9369 55.0709 9.00901 61.3966 9.00901C65.8618 9.00901 70.327 9.68468 74.4201 11.2613V63.0631Z"
      fill="currentColor"
    />
    <path
      d="M5.55968 10.5153V63.0106C5.55968 63.0598 5.60958 63.0933 5.65512 63.0747C16.0264 58.8253 27.8136 59.863 37.2829 65.8591L38.7 66.7564C38.7504 66.7883 38.8162 66.7521 38.8162 66.6925V15.4639C38.8162 15.4379 38.8032 15.4136 38.7816 15.3992L36.8863 14.1285C27.6581 7.94122 16.0163 6.57248 5.60464 10.4506C5.57761 10.4607 5.55968 10.4865 5.55968 10.5153Z"
      fill="white"
      stroke="currentColor"
      stroke-width="1.06314"
    />
    <path
      d="M75.2108 10.8186V63.3139C75.2108 63.3631 75.1609 63.3965 75.1154 63.3779C64.7441 59.1285 52.9569 60.1662 43.4876 66.1623L42.0705 67.0596C42.0201 67.0916 41.9543 67.0553 41.9543 66.9957V15.7671C41.9543 15.7412 41.9673 15.7169 41.9889 15.7024L43.8842 14.4317C53.1124 8.24444 64.7542 6.87571 75.1659 10.7539C75.1929 10.7639 75.2108 10.7897 75.2108 10.8186Z"
      fill="white"
      stroke="currentColor"
      stroke-width="1.06314"
    />
    <path
      d="M35.03 23.0327V28.2241C35.03 28.2936 34.9611 28.3419 34.8958 28.3183L34.3591 28.1237C26.2582 25.187 17.5159 24.4855 9.05037 26.0931C8.99124 26.1043 8.93648 26.059 8.93648 25.9988V21.2382C8.93648 21.1925 8.96855 21.1531 9.01328 21.1438C17.5024 19.3853 26.3087 19.9504 34.5034 22.7797L34.9628 22.9383C35.003 22.9522 35.03 22.9901 35.03 23.0327Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.06314"
    />
    <path
      d="M45.8428 23.48V28.6714C45.8428 28.7408 45.9118 28.7892 45.977 28.7655L46.5137 28.571C54.6146 25.6342 63.3569 24.9328 71.8224 26.5403C71.8816 26.5516 71.9363 26.5062 71.9363 26.446V21.6854C71.9363 21.6398 71.9042 21.6004 71.8595 21.5911C63.3704 19.8325 54.5641 20.3977 46.3694 23.227L45.91 23.3856C45.8698 23.3995 45.8428 23.4374 45.8428 23.48Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.06314"
    />
    <path
      d="M35.03 35.4202V40.6116C35.03 40.681 34.9611 40.7294 34.8958 40.7057L34.3591 40.5112C26.2582 37.5744 17.5159 36.873 9.05037 38.4805C8.99124 38.4917 8.93648 38.4464 8.93648 38.3862V33.6256C8.93648 33.5799 8.96855 33.5405 9.01328 33.5313C17.5024 31.7727 26.3087 32.3379 34.5034 35.1672L34.9628 35.3258C35.003 35.3397 35.03 35.3776 35.03 35.4202Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.06314"
    />
    <path
      d="M45.8428 35.8674V41.0588C45.8428 41.1283 45.9118 41.1767 45.977 41.153L46.5137 40.9584C54.6146 38.0217 63.3569 37.3202 71.8224 38.9278C71.8816 38.939 71.9363 38.8937 71.9363 38.8335V34.0729C71.9363 34.0272 71.9042 33.9878 71.8595 33.9785C63.3704 32.22 54.5641 32.7852 46.3694 35.6144L45.91 35.773C45.8698 35.7869 45.8428 35.8248 45.8428 35.8674Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.06314"
    />
    <path
      d="M35.03 47.8076V52.999C35.03 53.0685 34.9611 53.1168 34.8958 53.0932L34.3591 52.8986C26.2582 49.9619 17.5159 49.2604 9.05037 50.868C8.99124 50.8792 8.93648 50.8339 8.93648 50.7737V46.0131C8.93648 45.9674 8.96855 45.928 9.01328 45.9187C17.5024 44.1602 26.3087 44.7253 34.5034 47.5546L34.9628 47.7132C35.003 47.7271 35.03 47.765 35.03 47.8076Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.06314"
    />
    <path
      d="M45.8428 48.2549V53.4463C45.8428 53.5157 45.9118 53.5641 45.977 53.5404L46.5137 53.3459C54.6146 50.4091 63.3569 49.7077 71.8224 51.3152C71.8816 51.3265 71.9363 51.2811 71.9363 51.2209V46.4603C71.9363 46.4147 71.9042 46.3753 71.8595 46.366C63.3704 44.6074 54.5641 45.1726 46.3694 48.0019L45.91 48.1605C45.8698 48.1744 45.8428 48.2123 45.8428 48.2549Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.06314"
    />
    <rect
      x="37.6907"
      y="8.98096"
      width="5.11638"
      height="60.6982"
      fill="currentColor"
    />
  </svg>
);
