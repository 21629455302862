import React from 'react';

export const Printer: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="75"
    height="75"
    viewBox="0 0 75 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M63.75 20.8333H60V0H15V20.8333H11.25C5.025 20.8333 0 26.4167 0 33.3333V58.3333H15V75H60V58.3333H75V33.3333C75 26.4167 69.975 20.8333 63.75 20.8333ZM22.5 8.33333H52.5V20.8333H22.5V8.33333ZM52.5 58.3333V66.6667H22.5V50H52.5V58.3333ZM60 50V41.6667H15V50H7.5V33.3333C7.5 31.0417 9.1875 29.1667 11.25 29.1667H63.75C65.8125 29.1667 67.5 31.0417 67.5 33.3333V50H60Z"
      fill="currentColor"
    />
  </svg>
);
