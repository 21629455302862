import React from 'react';

export const GenderNeutral: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="82"
    height="82"
    viewBox="0 0 69 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.2838 81.4459V57.1621H36.4257L26.1455 26.2813C25.0123 22.9625 21.9363 20.7365 18.4557 20.7365H17.97C14.4893 20.7365 11.3729 22.9625 10.2801 26.2813L0 57.1621H12.1419V81.4459H24.2838ZM18.2128 16.6892C22.7053 16.6892 26.3074 13.0871 26.3074 8.59459C26.3074 4.10209 22.7053 0.5 18.2128 0.5C13.7203 0.5 10.1182 4.10209 10.1182 8.59459C10.1182 13.0871 13.7203 16.6892 18.2128 16.6892Z"
      fill="currentColor"
    />
    <path
      d="M46.2638 81.4459V51.0912H40.1929V28.8311C40.1929 24.379 43.8354 20.7365 48.2875 20.7365H60.4293C64.8814 20.7365 68.5239 24.379 68.5239 28.8311V51.0912H62.453V81.4459H46.2638ZM54.3584 16.6892C58.8509 16.6892 62.453 13.0871 62.453 8.59459C62.453 4.10209 58.8509 0.5 54.3584 0.5C49.8659 0.5 46.2638 4.10209 46.2638 8.59459C46.2638 13.0871 49.8659 16.6892 54.3584 16.6892Z"
      fill="currentColor"
    />
  </svg>
);
