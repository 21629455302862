import { Button } from '@/components/basic';
import WeekdayPicker from '@/components/basic/DatePicker/WeekdayPicker';
import ApprovalRequiredAlert from '@/components/partial/ApprovalRequiredAlert';
import RestrictionAlert from '@/components/partial/RestrictionAlert/RestrictionAlert';
import TimeZoneMismatchAlert from '@/components/partial/TimeZoneMismatchAlert';
import UserHealthRestrictedAlert from '@/components/partial/UserHealthRestrictedAlert';
import ReserveFieldDateMultiple from '@/components/reserve/ReserveFieldDateMultiple/ReserveFieldDateMultiple';
import ReserveFieldDuration from '@/components/reserve/ReserveFieldDuration/ReserveFieldDuration';
import ReserveFieldOrganizer from '@/components/reserve/ReserveFieldOrganizer/ReserveFieldOrganizer';
import ReserveFieldParkingList from '@/components/reserve/ReserveFieldParkingList/ReserveFieldParkingList';
import ReserveFieldRecurring from '@/components/reserve/ReserveFieldRecurring/ReserveFieldRecurring';
import ReserveFieldResourcesList from '@/components/reserve/ReserveFieldResourcesList/ReserveFieldResourcesList';
import ReserveFieldStartDate from '@/components/reserve/ReserveFieldStartDate/ReserveFieldStartDate';
import ReserveFieldTime from '@/components/reserve/ReserveFieldTime/ReserveFieldTime';
import ReserveFieldUsers from '@/components/reserve/ReserveFieldUsers/ReserveFieldUsers';
import { Form } from '@/components/ui/form';
import FloorFilterDropdown from '@/components/util/FloorFilterDropdown';
import { LoadingSpinner } from '@/components/util/LoadingSpinner';
import OfficeFilterDropdown from '@/components/util/OfficeFilterDropdown';
import { useI18n } from '@/hooks/useI18n';
import { useRedirectAfterReserve } from '@/hooks/useRedirectAfterReserve';
import useReserveResources from '@/hooks/useReserveResources';
import { useOfficeRestrictions } from '@/hooks/useRestrictions';
import {
  getDefaultsHoursForOffice,
  isNoPastReservations,
  isRBFeature,
  isTacticStaffOrImpersonated,
  showRecurring
} from '@/lib/featureFlags';
import { canScheduleOthers } from '@/lib/permissions';
import {
  convertTZ,
  getMinDateAllowedToReserve,
  getTimeForReservation
} from '@/lib/utils';
import {
  getDurationLabel,
  getDurationOptions,
  getOfficeHealthReservationRestrictions,
  noParkingResource
} from '@/lib/utils-reserve';
import {
  type SaveReservationData,
  saveReservations
} from '@/lib/utils-reserve';
import { isValidEmail } from '@/lib/validation/email';
import type {
  IResource,
  ISchedule,
  Office,
  Resource,
  ResourceType
} from '@gettactic/api';
import { useFloors } from '@gettactic/hooks';
import { useAuthenticated } from '@gettactic/hooks';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { MinusIcon, PencilAltIcon, PlusIcon } from '@heroicons/react/solid';
import { Collapse, Popover } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format, isPast, subMinutes } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import ReserveFieldDescription from '../ReserveFieldDescription/ReserveFieldDescription';
import ReserveFieldTitle from '../ReserveFieldTitle/ReserveFieldTitle';

export type ReserveProps = {
  /**
   * If schedule is passed, we will use the embedded resource object
   * to get the resource type. This is only used for new reservations.
   */
  resourceType: ResourceType;
  /**
   * Denotes if the reserve component is being invoked in Microsoft Teams iframe or not.
   */
  embedded: boolean;
  /**
   * Start date for the reservation
   */
  start?: Date;
  /**
   * Start time for the reservation e.g. 9:00 AM
   */
  time?: Date | null;
  /**
   * Duration for the reservation in minutes
   */
  duration?: number | null;
  /**
   * If passed, office will be pre-selected, otherwise will use the user's default office
   */
  office?: Office | null;
  /**
   * If passed, will pre-select the floor
   */
  floorId?: string | null;
  /**
   * If passed, will pre-select the resource. This will only be used for creating a new reservation.
   * e.g. when clicking on a map node, we pass the resource id to pre-select it.
   * If editing a reservation, we should be using the resource from the schedule object.
   */
  resource?: Resource | null;
  /**
   * Only passed when we're rendering the component to edit a reservation
   */
  schedule?: ISchedule;
  /**
   * If the component is rendered inside a modal e.g. <ReserveModal /> we pass this prop to allow
   * for triggering modal close from the child component.
   */
  onClose?: () => void;
};

export const Reserve = ({
  resourceType = 'desk',
  embedded = false,
  start,
  time = null,
  duration,
  office: selectedOfficeProp,
  resource,
  floorId = null,
  schedule,
  onClose
}: ReserveProps) => {
  const {
    userContext: { authenticatedUser, currentOffice }
  } = useAuthenticated();
  const isRBFlag = !!isRBFeature(authenticatedUser);
  const { templates } = useI18n(authenticatedUser);
  const { setAfterReserve } = useRedirectAfterReserve();

  // Client state hooks
  const [selectedOffice, setSelectedOffice] = useState(
    selectedOfficeProp ?? currentOffice
  );
  const [filterFloor, setFilterFloor] = useState<string | null>(
    floorId ?? authenticatedUser.defaultAreaId
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [notesOpened, setNotesOpened] = useState(false);
  const [enableAdminReservation, setEnableAdminReservation] = useState(false);

  // Data hooks
  const queryClient = useQueryClient();
  const { floors } = useFloors(selectedOffice?.id ?? '');

  // We should validate that the floor filter selected
  // exists among the current floor list
  useEffect(() => {
    if (!floors || !filterFloor) {
      return;
    }
    if (!floors.find((x) => x.id === filterFloor)) {
      setFilterFloor(null);
    }
  }, [floors, filterFloor]);

  // Resource Types
  const isMeetingRoom = resourceType === 'meeting_room';
  const isDesk = resourceType === 'workspace' || resourceType === 'desk';
  const isParking =
    resourceType === 'parking_space' || resourceType === 'parking_lot';
  const allowOthers = canScheduleOthers(authenticatedUser);

  // If we aren't given a start date, use the current date/time in the selected office's timezone
  const startOfficeDate: Date =
    start || convertTZ(new Date(), selectedOffice.time_zone);
  const offices = authenticatedUser.offices?.offices ?? [];
  const { meetingRoomDuration, deskDuration } = getDefaultsHoursForOffice(
    selectedOffice.id
  );

  // Default or last accessed values
  const lastAccessedResourceId = isMeetingRoom
    ? authenticatedUser?.user?.organization_user
        .last_accessed_meeting_room_resource_id || null
    : authenticatedUser?.user?.organization_user
        .last_accessed_desk_resource_id || null;
  const lastAccessedParkingResourceId =
    authenticatedUser?.user?.organization_user
      .last_accessed_parking_resource_id || noParkingResource.id;
  const currentOfficeTz = selectedOffice.time_zone;
  const currentOfficeId = selectedOffice.id;
  const currentOfficeDayIndex = convertTZ(new Date(), currentOfficeTz).getDay();
  const durationDefault = isMeetingRoom
    ? meetingRoomDuration * 60
    : deskDuration * 60;
  const defaultValues = {
    title: '',
    resource_id: resource?.id ?? lastAccessedResourceId,
    parking_resource_id: lastAccessedParkingResourceId,
    start: startOfficeDate,
    time: getTimeForReservation(
      startOfficeDate,
      time,
      currentOfficeTz,
      currentOfficeId
    ),
    // Use the meeting room duration (1 hour), even if the map start/end time is widened e.g. 9:00 - 17:00
    duration: duration && !isMeetingRoom ? duration : durationDefault,
    description: ''
  };

  if (!defaultValues.start || !(defaultValues.start instanceof Date)) {
    defaultValues.start = getMinDateAllowedToReserve(selectedOffice.time_zone);
  }

  const form = useForm<SaveReservationData>({
    defaultValues: {
      title: defaultValues.title,
      weekDates: [defaultValues.start],
      description: defaultValues.description
    }
  });

  // Watching form values due to field dependencies
  const weekDates = form.watch('weekDates');
  const users = (form.watch('users') ?? []).map((x) => x.value);
  const organizer = form.watch('organizer');
  const currentDuration = form.watch('duration', {
    value: defaultValues.duration
  });

  const weekDatesStr = weekDates.map((weekDate: Date) =>
    format(weekDate, 'yyyy-MM-dd')
  );
  const [durationMins, setDurationMins] = useState<number>(
    () => defaultValues.duration
  );
  const [startTime, setStartTime] = useState<Date>(() => defaultValues.time);

  // When reserving on behalf of another user, use _that_ person's resource permissions & assignments
  const userIdForResources = enableAdminReservation
    ? isMeetingRoom
      ? (organizer?.value ?? null)
      : users[0]
    : null;
  const { resourcesList, office, parkingResourcesList } = useReserveResources({
    resourceType,
    officeId: currentOfficeId,
    durationMins,
    startTime,
    weekDatesStr,
    filterParent: filterFloor,
    organizer: isValidEmail(userIdForResources) ? null : userIdForResources
  });
  const { restrictions } = useOfficeRestrictions(
    currentOfficeId,
    startTime,
    weekDatesStr,
    durationMins,
    []
  );

  // If we pass a resource to the component, we show that resource EVEN if it is not available eg in the list
  // This allows schedulers/admins to choose a resource from the map, even when they don't have permission to reserve it themselves.
  // It also facilitates meeting room reservations that are not perfectly aligned with the default map filter times.
  const { updatedResourcesList, defaultResource } = useMemo(() => {
    let updatedResourcesList = resourcesList;
    let defaultResource: IResource | null =
      defaultValues.resource_id && resourcesList
        ? (resourcesList.find(
            (resource) => resource.id === defaultValues.resource_id
          ) ?? null)
        : null;
    if (resource && resourcesList) {
      defaultResource = resource;
      // If the default resource is not available, add it to the list so it can be selected. Omit if it is already in the list.
      if (!resourcesList.find((x) => x.id === resource.id)) {
        updatedResourcesList = [...resourcesList, resource];
      }
    }
    return {
      updatedResourcesList,
      defaultResource
    };
  }, [resourcesList, resource, defaultValues]);

  const defaultParking = useMemo(() => {
    return (
      parkingResourcesList?.find(
        (x) => x.id === defaultValues.parking_resource_id
      ) ?? noParkingResource
    );
  }, [parkingResourcesList, defaultValues, noParkingResource]);

  const durationOptions = useMemo(
    () => getDurationOptions(startTime),
    [startTime, currentOfficeTz]
  );
  const currentResource = form.watch('resource', defaultResource ?? undefined);

  const userHealthRestricted = !enableAdminReservation
    ? getOfficeHealthReservationRestrictions(
        authenticatedUser,
        office ?? undefined
      )
    : false;

  const submitMutation = useMutation(
    async (data: SaveReservationData) => {
      const authUserId = authenticatedUser?.user?.id;

      // If the user is not allowed to schedule in the past & the time selected is more than 5 minutes in the past, throw an error message
      // if (
      //   isNoPastReservations(authenticatedUser) &&
      //   isPast(subMinutes(data.time, 5))
      // ) {
      //   setError(
      //     'Reservations in the past are disabled. Please choose a time in the future.'
      //   );
      //   throw new Error();
      // }

      if (!office || !authUserId) {
        // just to avoid ts warning
        throw new Error('Not required params');
      }
      setLoading(true);

      if (!data.duration?.value || data.duration.value < 5) {
        form.setError('duration', {
          type: 'manual',
          message: 'Duration must be at least 5 minutes'
        });
        throw new Error();
      }

      const { hasError, message } = await saveReservations(data, {
        organizerId: data.organizer?.value ?? null,
        isMeetingRoom,
        authUserId,
        office,
        // allowOthers && the admin has chosen a user
        allowOthers: enableAdminReservation,
        timeZone: office.time_zone,
        officeId: currentOfficeId,
        noParkingResource,
        dateFormat: templates.displayDate,
        orgSlug: authenticatedUser.slug || "",
      });

      if (hasError && message) {
        setError(message);
        throw new Error();
      }
    },
    {
      onError: () => {
        setLoading(false);
      },
      onSuccess: () => {
        // This should return false for 95% of users currently.
        // TODO: Investigate if there is a less expensive way to determine if we should call this.
        setAfterReserve(selectedOffice.id);

        // If we're in a auto closing modal or drawer, close it
        onClose ? onClose() : null;
      },
      onSettled: () => {
        queryClient.invalidateQueries(['schedules']);
        queryClient.invalidateQueries(['organizations.schedulable']);
        queryClient.invalidateQueries(['organizations.questionnaire']);
        setLoading(false);
      }
    }
  );

  const reservationDurationLabel = useMemo(
    () => getDurationLabel(durationMins),
    [durationMins]
  );

  const onSubmit: SubmitHandler<SaveReservationData> = async (data) => {
    submitMutation.mutate(data);
  };

  const getCurrentWeek = (officeTZ: string) => {
    const officeTZDate = convertTZ(new Date(), officeTZ);
    return Array(7)
      .fill(new Date(officeTZDate))
      .map((el, idx) => new Date(el.setDate(el.getDate() - el.getDay() + idx)));
  };
  const modalRef = useRef<HTMLDivElement | null>(null);

  const enableFloorSelector = (floors?.length ?? 0) > 1;
  const totalParking =
    (office?.total_parking_lots ?? 0) + (office?.total_parking_spaces ?? 0);

  return (
    <>
      {onClose ? (
        <TimeZoneMismatchAlert
          embedded={embedded}
          onClose={onClose}
          className="mb-5 max-w-xl"
          timezone={currentOfficeTz || ''}
          officeId={currentOfficeId}
        />
      ) : null}

      {restrictions ? (
        <RestrictionAlert
          restrictions={restrictions.restrictions}
          className="mb-2 max-w-xl"
        />
      ) : null}
      <UserHealthRestrictedAlert
        restricted={userHealthRestricted}
        className="mb-2 max-w-xl"
      />
      {currentResource?.is_approvable ? (
        <ApprovalRequiredAlert className="mb-2 max-w-xl" />
      ) : null}
      <Form {...form}>
        <form
          className="flex flex-col text-left"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col" ref={modalRef}>
            {allowOthers ? (
              <div className="mt-2 text-left">
                <div>
                  <button
                    onClick={(ev) => {
                      ev.preventDefault();
                      setEnableAdminReservation((x) => !x);
                    }}
                    className="flex items-center pb-2 text-secondary"
                  >
                    {!enableAdminReservation ? (
                      <PlusIcon className="mr-1.5 h-6 w-4" />
                    ) : (
                      <MinusIcon className="mr-1.5 h-6 w-4" />
                    )}
                    Create Reservation for Another User
                  </button>
                  {enableAdminReservation ? (
                    <div className="text-gray-500">
                      <div className="relative pb-4">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                      </div>
                      {!isMeetingRoom ? (
                        <>
                          <div className="flex items-center">
                            <span className="text-secondary">User</span>
                            <Popover width={250} position="top" shadow="md">
                              <Popover.Target>
                                <InformationCircleIcon className="ml-2 h-5 w-5 cursor-pointer text-gray-400" />
                              </Popover.Target>
                              <Popover.Dropdown className="z-20">
                                <span>
                                  As an admin or scheduler, you can make
                                  reservation on behalf of other users.
                                </span>
                              </Popover.Dropdown>
                            </Popover>
                          </div>
                          <ReserveFieldUsers
                            single={true}
                            form={form}
                            defaultValue={users}
                            errors={form.formState.errors}
                          />
                        </>
                      ) : (
                        <>
                          <div className="flex items-center">
                            <span className="text-secondary">Organizer</span>
                            <Popover width={300} position="top" shadow="md">
                              <Popover.Target>
                                <InformationCircleIcon className="ml-2 h-5 w-5 cursor-pointer text-gray-400" />
                              </Popover.Target>
                              <Popover.Dropdown className="z-20">
                                <span>
                                  Select an organizer for the meeting. As you
                                  are creating this reservation on behalf of
                                  another user, you will not be the organizer,
                                  nor one of the invitees.
                                </span>
                              </Popover.Dropdown>
                            </Popover>
                          </div>
                          <ReserveFieldOrganizer
                            form={form}
                            defaultValue={
                              (organizer ?? users)
                                ? { value: users[0] }
                                : undefined
                            }
                            errors={form.formState.errors}
                          />
                        </>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            {isMeetingRoom ? (
              <div className="mt-2">
                <ReserveFieldTitle form={form} />
              </div>
            ) : null}
            <div className="grid grid-cols-3 gap-3">
              <div className="mt-2">
                {office ? (
                  !isMeetingRoom ? (
                    <ReserveFieldDateMultiple
                      form={form}
                      weekDates={weekDates}
                      officeTimeZone={currentOfficeTz}
                      errors={form.formState.errors}
                      resType={resourceType}
                      office={office}
                      modalRef={modalRef}
                    />
                  ) : (
                    <ReserveFieldStartDate
                      form={form}
                      defaultValue={weekDates}
                      startTime={startTime}
                      weekDates={weekDates}
                      officeTimeZone={currentOfficeTz}
                      errors={form.formState.errors}
                      resType={resourceType}
                      office={office}
                    />
                  )
                ) : null}
              </div>
              <div className="mt-2">
                <ReserveFieldTime
                  form={form}
                  multipleDays={weekDates.length > 1}
                  defaultValue={defaultValues.time}
                  officeTimeZone={currentOfficeTz}
                  officeId={currentOfficeId}
                  errors={form.formState.errors}
                  setStartTime={setStartTime}
                  weekDates={weekDates}
                />
              </div>
              <div className="mt-2 text-left">
                <ReserveFieldDuration
                  form={form}
                  startTime={startTime}
                  setDurationMin={setDurationMins}
                  durationOptions={durationOptions}
                  defaultValue={defaultValues.duration}
                  errors={form.formState.errors}
                />
              </div>
            </div>
            <div className={'mt-2'}>
              Reservation duration: {reservationDurationLabel}
            </div>
            {isRBFlag ? (
              <div className="mt-2 text-left">
                <WeekdayPicker
                  form={form}
                  weekDates={weekDates}
                  currentWeek={getCurrentWeek(currentOfficeTz)}
                  currentDayIdx={currentOfficeDayIndex}
                  singleSelect={isMeetingRoom}
                />
              </div>
            ) : null}

            <div className="mt-2 text-left">
              {!showRecurring(
                resourceType,
                authenticatedUser,
                selectedOffice
              ) && isTacticStaffOrImpersonated(authenticatedUser) ? (
                <p className="text-red-500">
                  Recurring visible due to impersonation:
                </p>
              ) : null}
              {showRecurring(resourceType, authenticatedUser, selectedOffice) ||
              isTacticStaffOrImpersonated(authenticatedUser) ? (
                <ReserveFieldRecurring
                  key={`${weekDates.map((x) => x.toString()).join(',') ?? ''}`}
                  errors={form.formState.errors}
                  defaultValue={''}
                  form={form}
                />
              ) : null}
            </div>

            {isMeetingRoom ? (
              <div className="mt-2 text-left">
                <ReserveFieldUsers
                  form={form}
                  single={false}
                  defaultValue={[]}
                  filterValues={(option) =>
                    option.value !== authenticatedUser?.user?.id
                  }
                  errors={form.formState.errors}
                />
              </div>
            ) : null}

            <div className="mt-2 grid grid-cols-2 gap-x-2">
              <div
                className={enableFloorSelector ? 'col-span-1' : 'col-span-2'}
              >
                <OfficeFilterDropdown
                  className="grow"
                  readonly={!!resource}
                  setOfficeId={(id) => {
                    const office =
                      offices.find((x) => x.id === id) ?? currentOffice;
                    setSelectedOffice(office);
                    setFilterFloor(null);
                  }}
                  officeId={selectedOffice?.id ?? null}
                  offices={offices}
                />
              </div>
              {enableFloorSelector ? (
                <div className="col-span-1">
                  <FloorFilterDropdown
                    readonly={!!resource}
                    floors={floors ?? []}
                    floor={filterFloor}
                    setFloor={setFilterFloor}
                  />
                </div>
              ) : null}
            </div>

            <div className="relative mt-3 text-left">
              <ReserveFieldResourcesList
                form={form}
                errors={form.formState.errors}
                resourceType={resourceType}
                officeId={currentOfficeId}
                resourcesList={updatedResourcesList}
                defaultValue={defaultResource}
              />
            </div>
            {isDesk && !isParking && office && totalParking > 0 ? (
              <div className="mt-3 mb-5">
                <ReserveFieldParkingList
                  noParkingResource={noParkingResource}
                  parkingResourcesList={parkingResourcesList}
                  defaultValue={defaultParking}
                  form={form}
                  errors={form.formState.errors}
                />
              </div>
            ) : null}
            {isMeetingRoom ? (
              <div className="mt-4 mb-2">
                <button
                  className="flex items-center pb-2 text-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setNotesOpened((s) => !s);
                  }}
                >
                  <PencilAltIcon className="mr-1.5 h-4 w-4" />
                  Add meeting notes
                </button>
                <Collapse in={notesOpened}>
                  <ReserveFieldDescription form={form} />
                </Collapse>
              </div>
            ) : null}
            <div className="pb-4 text-sm text-red-600">
              {error.split('\n').map((line) => (
                <p key={line}>{line}</p>
              ))}
            </div>
            <Button
              type="submit"
              className="w-full"
              variant="primary"
              layout="rounded"
              font="boldPrimary"
              disabled={
                loading ||
                !!userHealthRestricted ||
                !updatedResourcesList ||
                !currentResource
              }
            >
              {loading ? (
                <LoadingSpinner color="text-white" />
              ) : (
                <span>Reserve</span>
              )}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default Reserve;
