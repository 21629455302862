import React from 'react';

export const Phone: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5867 7.11111C12.8 10.2756 13.3333 13.3689 14.1867 16.32L9.92 20.5867C8.46222 16.32 7.53778 11.8044 7.21778 7.11111H12.5867ZM47.6444 49.8489C50.6667 50.7022 53.76 51.2356 56.8889 51.4489V56.7467C52.1956 56.4267 47.68 55.5022 43.3778 54.08L47.6444 49.8489ZM16 0H3.55556C1.6 0 0 1.6 0 3.55556C0 36.9422 27.0578 64 60.4444 64C62.4 64 64 62.4 64 60.4444V48.0356C64 46.08 62.4 44.48 60.4444 44.48C56.0356 44.48 51.7333 43.7689 47.7511 42.4533C47.3956 42.3111 47.0044 42.2756 46.6489 42.2756C45.7244 42.2756 44.8356 42.6311 44.1244 43.3067L36.3022 51.1289C26.24 45.9733 17.9911 37.76 12.8711 27.6978L20.6933 19.8756C21.6889 18.88 21.9733 17.4933 21.5822 16.2489C20.2667 12.2667 19.5556 8 19.5556 3.55556C19.5556 1.6 17.9556 0 16 0Z"
      fill="currentColor"
    />
  </svg>
);
