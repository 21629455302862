import { Button, Input } from '@/components/basic';
import { DatePicker } from '@/components/basic/DatePicker/DatePicker';
import { FormField } from '@/components/ui/form';
import { useI18n } from '@/hooks/useI18n';
import {
  getMaxDateAllowedToReserve,
  getMinDateAllowedToReserve
} from '@/lib/utils';
import { RESERVE_TEXTS, SaveReservationData } from '@/lib/utils-reserve';
import { Office, ResourceType } from '@gettactic/api';
import { useAuthenticated } from '@gettactic/hooks';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { FieldErrors, UseFormReturn } from 'react-hook-form';

type ReserveDateMultipleProps = {
  form: UseFormReturn<any>;
  weekDates: Date[];
  officeTimeZone: string;
  office?: Office;
  resType: ResourceType;
  errors: FieldErrors<SaveReservationData>;
  modalRef: React.RefObject<HTMLDivElement>;
};

export default function ReserveFieldDateMultiple({
  form,
  weekDates,
  officeTimeZone,
  office,
  resType,
  errors,
  modalRef
}: ReserveDateMultipleProps) {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const { templates } = useI18n(authenticatedUser);
  const [open, setOpen] = useState(false);
  const [localWeekDates, setLocalWeekDates] = useState(weekDates);
  const close = useCallback(() => {
    if (open) {
      form.setValue('weekDates', [...localWeekDates]);
    }
    setOpen(false);
  }, [open, localWeekDates]);

  const handleClickOutside = (event: Event) => {
    if (modalRef.current) {
      const mouseEvent = event as MouseEvent;
      const boundingRect = modalRef.current.getBoundingClientRect();
      // this is a workaround to NOT close the date picker when clicking on the modal scrollbar
      const isNotOnScrollBar = mouseEvent.clientX < boundingRect.right - 15;

      if (isNotOnScrollBar) {
        close();
      }
    }
  };

  const clickOutsideRef = useOnclickOutside(handleClickOutside);
  const minAllowedReserveDate = useMemo(
    () => getMinDateAllowedToReserve(officeTimeZone, weekDates),
    [officeTimeZone, weekDates]
  );
  const maxAllowedReserveDate = useMemo(
    () => getMaxDateAllowedToReserve(officeTimeZone, office, resType),
    [office, officeTimeZone, resType]
  );

  useEffect(() => {
    setLocalWeekDates(weekDates);
  }, [weekDates]);

  const value =
    localWeekDates.length > 1
      ? `Multiple Dates (${localWeekDates.length} days)`
      : localWeekDates.length === 0
        ? 'Select dates'
        : format(localWeekDates[0], templates.displayDate);
  return (
    <>
      <FormField
        control={form.control}
        name="weekDates"
        render={({ field }) => (
          <div>
            <Input
              readOnly={true}
              className="input w-full rounded-md"
              aria-readonly={true}
              value={value}
              onClick={() => setOpen(true)}
            />
            {open ? (
              <div
                ref={clickOutsideRef}
                className="inline-multiple absolute z-10 rounded bg-white p-2 text-center shadow-xl"
              >
                <DatePicker
                  inline
                  minDate={minAllowedReserveDate}
                  maxDate={maxAllowedReserveDate ?? undefined}
                  selected={null}
                  value={''}
                  dateFormat={templates.displayDate}
                  highlightDates={localWeekDates}
                  shouldCloseOnSelect={false}
                  onChange={(changeDate) => {
                    if (changeDate instanceof Date) {
                      const w: Date[] = [...localWeekDates];
                      const idx = w.findIndex(
                        (x) =>
                          format(x, 'yyyy-MM-dd') ===
                          format(changeDate, 'yyyy-MM-dd')
                      );
                      if (idx === -1) {
                        setLocalWeekDates([...w, changeDate]);
                      } else {
                        w.splice(idx, 1);
                        setLocalWeekDates(w);
                      }
                    }
                  }}
                  className="input w-full rounded-md"
                  calendarClassName="tailwind-datepicker"
                  placeholderText={RESERVE_TEXTS.selectStart}
                />
                <div className="mx-auto mt-2 max-w-xs">
                  <Button
                    className="w-full"
                    layout="rounded"
                    variant="primary"
                    font="boldPrimary"
                    onClick={() => {
                      setOpen(false);
                      form.setValue('weekDates', [...localWeekDates], {
                        shouldValidate: true
                      });
                    }}
                  >
                    Continue
                  </Button>
                  {localWeekDates.length <= 1 ? (
                    <p className="text-trueGray-300 mt-2">
                      Select multiple dates to book desks in bulk
                    </p>
                  ) : (
                    <button
                      className="mt-2 text-sm text-orange-300"
                      onClick={(ev) => {
                        ev.preventDefault();
                        form.setValue('weekDates', []);
                      }}
                    >
                      Clear {localWeekDates.length}
                      {localWeekDates.length === 1 ? ' date' : ' dates'}
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        )}
      />
      {errors.weekDates ? (
        <p className="text-primary">Please choose a valid date</p>
      ) : null}
    </>
  );
}
