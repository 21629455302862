import { canScheduleOthers } from '@/lib/permissions';
import { addCurrent } from '@/lib/utils-routing';
import { Permissions } from '@gettactic/api';
import {
  IconFromSet,
  IconValue
} from '@gettactic/components/src/icons/IconFromSet';
import { findDivisionPathByOfficeId } from '@gettactic/helpers/src/divisions/findDivisionPathByOfficeId';
import { useDivisionsMenuQuery, usePageSectionsQuery } from '@gettactic/hooks';
import { useAuthenticated } from '@gettactic/hooks';
import {
  ChartSquareBarIcon,
  CogIcon,
  FlagIcon,
  HomeIcon,
  MapIcon,
  UserGroupIcon
} from '@heroicons/react/outline';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import Scrollbars from 'react-custom-scrollbars';

interface NavSection {
  name: string;
  href: string;
  id: string | null;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  icon: any;
  permission?: boolean;
  iconSet?: IconValue;
  startsWith?: string;
  isAdd?: boolean;
  current?: boolean;
}

export const AppSideNav = ({
  permissions
}: { permissions?: Permissions; theme?: string }) => {
  const router = useRouter();

  const {
    userContext: { authenticatedUser, currentOffice }
  } = useAuthenticated();
  const divisions = useDivisionsMenuQuery();
  const root = divisions.data;

  const selectedDivision = currentOffice.division_id;
  const selectedOfficeId = currentOffice.id;

  const sections = usePageSectionsQuery();

  const sidebarNavigation = useMemo(() => {
    const pageSectionsRoot = sections || [];

    const divisionPath = findDivisionPathByOfficeId(
      root?.tree?.children || [],
      selectedOfficeId
    );

    // Note the permissions being passed for Reports
    const navSections = {
      primary: addCurrent<NavSection>(
        [
          {
            name: 'Home',
            href: '/dashboard',
            id: null,
            icon: HomeIcon,
            permission: true
          },
          {
            name: 'Map',
            href: '/map',
            id: null,
            icon: MapIcon,
            permission: true
          },
          {
            name: 'Directory',
            href: '/directory',
            id: null,
            icon: UserGroupIcon,
            permission: true
          },
          {
            name: 'Reports',
            href: '/reports',
            id: null,
            icon: ChartSquareBarIcon,
            permission: permissions?.settings
          }
        ],
        router
      ),
      secondary: addCurrent<NavSection>(
        [
          {
            name: 'Admin',
            href: '/admin',
            id: null,
            icon: FlagIcon,
            startsWith: '/admin',
            permission: canScheduleOthers(authenticatedUser)
          },
          {
            name: 'Settings',
            href: '/settings/profile',
            id: null,
            icon: CogIcon,
            startsWith: '/settings',
            permission: true
          }
        ],
        router
      )
    };

    if (pageSectionsRoot.length > 0) {
      pageSectionsRoot.forEach((section) => {
        if (
          section.parent_id === null &&
          ((section.division_id &&
            divisionPath?.includes(section.division_id)) ||
            (section.office_id && section.office_id === selectedOfficeId))
        ) {
          navSections.primary.push(
            ...addCurrent(
              [
                {
                  name: section.name,
                  href: `/pages/${section.id}`,
                  startsWith: `/pages/${section.id}`,
                  id: section.id,
                  icon: HomeIcon,
                  iconSet: section.image_url
                    ? JSON.parse(section.image_url)
                    : null,
                  permission: true
                }
              ],
              router
            )
          );
        }
      });
    }

    return navSections;
  }, [permissions, router, authenticatedUser, sections, selectedDivision]);

  return (
    <nav
      aria-label="sidebar"
      className="z-20 hidden shadow-2xl md:block md:shrink-0 md:bg-secondary"
    >
      <div className="flex h-full w-28 flex-col justify-between p-3">
        {permissions ? (
          <>
            <Scrollbars>
              <div className="space-y-3">
                {sidebarNavigation.primary
                  .filter((item) => item.permission)
                  .map((item) => (
                    <Link href={item.href} key={item.name}>
                      <a
                        className={clsx(
                          item.current
                            ? 'border-brand border-l-4 bg-highlight/20'
                            : 'hover:bg-highlight/10',
                          'group flex w-full flex-col items-center rounded-lg rounded-l-none p-3 text-primary-text'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.iconSet ? (
                          <IconFromSet icon={item.iconSet} size={8} />
                        ) : (
                          <item.icon
                            className={clsx('h-6 w-6')}
                            aria-hidden="true"
                          />
                        )}

                        <span className={clsx('mt-2 text-center')}>
                          {item.name}
                        </span>
                      </a>
                    </Link>
                  ))}
              </div>
            </Scrollbars>
            <div className="space-y-3">
              {sidebarNavigation.secondary
                .filter((item) => item.permission)
                .map((item) => (
                  <Link href={item.href} key={item.name}>
                    <a
                      id={item.id ? item.id : ''}
                      className={clsx(
                        item.current
                          ? 'border-highlight border-l-4 bg-highlight/20'
                          : 'hover:bg-highlight/10',
                        'group flex w-full flex-col items-center rounded-lg rounded-l-none p-3 text-primary-text'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon
                        className={clsx('h-6 w-6')}
                        aria-hidden="true"
                      />
                      <span className={clsx('mt-2')}>{item.name}</span>
                    </a>
                  </Link>
                ))}
            </div>
          </>
        ) : null}
      </div>
    </nav>
  );
};

export default AppSideNav;
