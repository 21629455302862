import React from 'react';

export const Stairs: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="75"
    height="75"
    viewBox="0 0 75 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M66.6667 8.33333V66.6667H8.33333V8.33333H66.6667ZM66.6667 0H8.33333C3.75 0 0 3.75 0 8.33333V66.6667C0 71.25 3.75 75 8.33333 75H66.6667C71.25 75 75 71.25 75 66.6667V8.33333C75 3.75 71.25 0 66.6667 0ZM62.5 12.5H44.0833V26.375H33.3333V40.25H22.5833V54.1667H12.5V62.5H30.9167V48.625H41.6667V34.75H52.4167V20.8333H62.5V12.5Z"
      fill="currentColor"
    />
  </svg>
);
