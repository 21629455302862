import React from 'react';

export const MaleRestroom: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 29 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.14907 80V50H0V28C0 23.6 3.68944 20 8.19876 20H20.4969C25.0062 20 28.6957 23.6 28.6957 28V50H22.5466V80H6.14907ZM14.3478 16C18.8981 16 22.5466 12.44 22.5466 8C22.5466 3.56 18.8981 0 14.3478 0C9.79752 0 6.14907 3.56 6.14907 8C6.14907 12.44 9.79752 16 14.3478 16Z"
      fill="currentColor"
    />
  </svg>
);
