import React from 'react';

export const Elevator: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="75"
    height="75"
    viewBox="0 0 75 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M66.6667 8.33333V66.6667H8.33333V8.33333H66.6667ZM66.6667 0H8.33333C3.75 0 0 3.75 0 8.33333V66.6667C0 71.25 3.75 75 8.33333 75H66.6667C71.25 75 75 71.25 75 66.6667V8.33333C75 3.75 71.25 0 66.6667 0ZM29.1667 62.5V45.8333H33.3333V35.4167C33.3333 30.8333 29.5833 27.0833 25 27.0833H20.8333C16.25 27.0833 12.5 30.8333 12.5 35.4167V45.8333H16.6667V62.5H29.1667ZM22.9167 22.9167C25.7917 22.9167 28.125 20.5833 28.125 17.7083C28.125 14.8333 25.7917 12.5 22.9167 12.5C20.0417 12.5 17.7083 14.8333 17.7083 17.7083C17.7083 20.5833 20.0417 22.9167 22.9167 22.9167ZM62.5 33.3333L52.0833 16.6667L41.6667 33.3333H62.5ZM41.6667 41.6667L52.0833 58.3333L62.5 41.6667H41.6667Z"
      fill="currentColor"
    />
  </svg>
);
