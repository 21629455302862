import { triggerWebviewAction } from './triggerWebviewAction';

export const webviewLog = (message: string) => {
  const sendMessage = () => {
    if ('ReactNativeWebView' in window && window.ReactNativeWebView) {
      triggerWebviewAction('log', { message });
    } else {
      setTimeout(sendMessage, 100);
    }
  };

  sendMessage();
};
