import { useBooleanState } from '@gettactic/helpers';
import { getApiErrorMessage } from '@gettactic/helpers/src/errors/getApiErrorMessage';
import React from 'react';
import { Button } from '../../../buttons';
import { Modal } from '../Modal';
import { ConfirmModalProps } from './ConfirmModal.types';

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  message,
  onOpenChange,
  onConfirm,
  onCancel,
  confirmProps,
  cancelProps,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  footerProps,
  isOpen,
  children,
  ...rest
}) => {
  const isLoading = useBooleanState(false);
  const [asyncError, setAsyncError] = React.useState('');

  const handleConfirm = async () => {
    setAsyncError('');

    if (onConfirm) {
      isLoading.true();
      const results = await onConfirm();
      if (results) {
        const error = getApiErrorMessage(results);
        setAsyncError(error);
      }
      isLoading.false();
    }
  };

  const handleClose = () => {
    if (isLoading.state) return;
    onOpenChange?.(false);
  };

  const handleCancel = () => {
    if (isLoading.state) return;

    if (onCancel) {
      onCancel();
    }

    handleClose();
  };

  return (
    <Modal
      opened={isOpen}
      {...rest}
      closeOnOverlayClick={isLoading.state ? false : rest.closeOnOverlayClick}
      onOpenChange={(isOpen) => !isOpen && handleClose()}
      onClose={() => null}
    >
      {message}
      {children}
      <Modal.Footer {...footerProps}>
        <Button
          onClick={handleCancel}
          className="w-full sm:w-auto"
          layout="squared"
          variant="white"
          font="boldGray"
          disabled={isLoading.state}
          {...(cancelProps as object)}
        >
          {cancelLabel}
        </Button>
        <Button
          onClick={handleConfirm}
          className="w-full sm:ml-3 sm:w-auto"
          layout="squared"
          variant="primary"
          type="submit"
          loading={isLoading.state}
          {...(confirmProps as object)}
        >
          {confirmLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
