import { FormField, FormItem, FormMessage } from '@/components/ui/form';
import SelectUsersAsync from '@/components/users/SelectUsersAsync';
import { SaveReservationData } from '@/lib/utils-reserve';
import { useAuthenticated } from '@gettactic/hooks';
import { FieldErrors, UseFormReturn } from 'react-hook-form';

type ReserveFieldUsersProps = {
  form: UseFormReturn<any>;
  defaultValue: string[];
  filterValues?: (_: { value: string }) => boolean;
  errors: FieldErrors<SaveReservationData>;
  single?: boolean;
  readonly?: boolean;
};
export default function ReserveFieldUsers({
  form,
  defaultValue,
  filterValues,
  single = false,
  readonly = false
}: ReserveFieldUsersProps) {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const isTeamManager =
    !!authenticatedUser?.user?.permissions.includes(
      'schedule:others:managed:add'
    ) && !authenticatedUser?.user?.permissions.includes('schedule:others:add');

  return (
    <>
      <FormField
        control={form.control}
        name="users"
        render={({ field }) => (
          <FormItem>
            <SelectUsersAsync
              setInitial={(users) => {
                // Before we can set default values we need to do an async call to retrieve IDs.
                form.setValue('users', users, { shouldValidate: false });
              }}
              onChange={(value) => {
                field.onChange(single ? [value] : value);
              }}
              readonly={readonly}
              value={field.value}
              className="w-full rounded-md border-gray-300"
              classNamePrefix="react-select"
              isMulti={!single}
              defaultValue={defaultValue}
              placeholder={single ? `Select User` : `Add Attendees`}
              filterValues={filterValues}
              managed={isTeamManager}
            />
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}
